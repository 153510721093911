import { createRoot } from "react-dom/client";
import App from "Components/App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "Data/Redux/Persistor";
import { store } from "Data/Redux/Store";
import ErrorBoundary from "Components/ErrorBoundary";
import "./Styles/index.less";
import initFontAwesome from "src/FontAwesome";

initFontAwesome();

const appComponent = (
	<ErrorBoundary>
		<Provider store={ store }>
			<PersistGate loading={ null } persistor={ persistor } >
				<App />
			</PersistGate>
		</Provider>
	</ErrorBoundary>
);

createRoot(document.getElementById("app"))
	.render(appComponent);

