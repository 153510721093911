// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-bar {
  align-items: center;
  background: #127ec0;
  color: #ffffff;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  padding-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TitleBar/TitleBar.less"],"names":[],"mappings":"AAEA;EACC,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;AADD","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.title-bar {\n\talign-items: center;\n\tbackground: @primary;\n\tcolor: #ffffff;\n\tdisplay: flex;\n\theight: 50px;\n\tjustify-content: flex-end;\n\tpadding-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
