const monthsShort = [
	"Jan", "Feb", "Mar", "Apr", "May", "June",
	"July", "Aug", "Sept", "Oct", "Nov", "Dec"
];

const monthsLong = [
	"January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
];

export enum DateUnits {
	SECONDS = 1000,
	MINUTES = 60 * DateUnits.SECONDS,
	HOURS = 60 * DateUnits.MINUTES,
	DAYS = 24 * DateUnits.HOURS,
	YEARS = 365 * DateUnits.DAYS
}

function getOrdinalString(input: number) {
	const rightDigit = input % 10;
	const rightTwoDigits = input % 100;

	if (rightDigit == 1 && rightTwoDigits != 11) {
		return input + "st";
	}

	if (rightDigit == 2 && rightTwoDigits != 12) {
		return input + "nd";
	}

	if (rightDigit == 3 && rightTwoDigits != 13) {
		return input + "rd";
	}

	return input + "th";
}

export function formatDate(date: Date, format: string) {
	if (!date) {
		return "Invalid Date";
	}

	if (!format) {
		return date.toISOString();
	}

	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	const hour = date.getHours();
	const minute = date.getMinutes();
	const seconds = date.getSeconds();
	const isAM = hour < 12;
	const tokenMap = {
		YYYY: `${ year }`,
		Y: `${ year % 100 }`,
		MMMM: monthsLong[month],
		MMM: monthsShort[month],
		MM: `${ month + 1 }`.padStart(2, "0"),
		M: `${ month + 1 }`,
		Do: getOrdinalString(day),
		DD: `${ day }`.padStart(2, "0"),
		D: `${ day }`,
		HH: `${ hour }`.padStart(2, "0"),
		H: `${ hour }`,
		hh: `${ (hour % 12) || 12 }`.padStart(2, "0"),
		h: `${ (hour % 12) || 12 }`,
		mm: `${ minute }`.padStart(2, "0"),
		m: `${ minute }`,
		ss: `${ seconds }`.padStart(2, "0"),
		s: `${ seconds }`,
		A: isAM ? "AM" : "PM",
		a: isAM ? "am" : "pm"
	};

	const tokenExpression = new RegExp(Object.keys(tokenMap).join("|"),"g");

	return format.replace(tokenExpression, (matchedToken) => {
		return tokenMap[matchedToken];
	});
}

export function floorDate(date: Date) {
	const newDate = new Date(date);

	newDate.setHours(0);
	newDate.setMinutes(0);
	newDate.setSeconds(0);
	newDate.setMilliseconds(0);

	return newDate;
}

export function getISOString(date: Date) {
	return date.toISOString().slice(0, -5) + "+00:00";
}

export function diffDates(date1: Date, date2: Date, units: DateUnits = DateUnits.SECONDS) {
	return (date1.valueOf() - date2.valueOf()) / units;
}

export function diffDatesAbs(date1: Date, date2: Date, units?: DateUnits) {
	return Math.abs(diffDates(date1, date2, units ?? DateUnits.SECONDS));
}

export function formatDurationString(date1: Date, date2: Date) {
	const durationRaw = Math.abs(date1.valueOf() - date2.valueOf());
	const durationSeconds = Math.floor(durationRaw / DateUnits.SECONDS);
	const seconds = durationSeconds % 60;
	const durationMinutes = ((durationSeconds - seconds) / 60);
	const minutes = durationMinutes % 60;
	const hours = ((durationMinutes - minutes) / 60);
	const durationParts = [];

	if (hours > 0) {
		durationParts.push(`${ hours }h`);
	}

	if (minutes > 0) {
		durationParts.push(`${ minutes }m`);
	}

	if (seconds > 0) {
		durationParts.push(`${ seconds }s`);
	}

	return durationParts.join(" ");
}

export function copyTimeToDate(time: string, date: Date) {
	const timeParts = time.split(":");
	const newDate = new Date(date.valueOf());

	newDate.setHours(parseInt(timeParts[0]));
	newDate.setMinutes(parseInt(timeParts[1]));
	newDate.setSeconds(parseInt(timeParts[2] ?? "0"));

	return newDate;
}
