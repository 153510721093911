// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-sider {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 350px;
  min-width: 210px;
}
.orders-sider-header {
  align-items: center;
  background: #127ec0;
  color: #ffffff;
  display: flex;
  font-size: 28px;
  height: 50px;
  justify-content: center;
}
.orders-sider-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}
.orders-sider-body::-webkit-scrollbar-thumb {
  background-color: #8e8e8e;
  border-radius: 5px;
}
.orders-sider-body::-webkit-scrollbar {
  background-color: #262a2e;
  border-radius: 5px;
  width: 10px;
}
.orders-sider-content {
  color: #ffffff;
  flex: 1;
}
.date-picker {
  color: #7e7e7e;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.scan-date-picker {
  margin-top: 5px;
}
.sider-block {
  border-bottom: 2px solid #202325;
  padding: 15px 25px;
}
.range-inputs {
  display: flex;
}
.range-inputs .cec-input-wrapper:first-child {
  margin-right: 10px;
}
.orders-sider-footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
}
.orders-sider-footer button {
  margin-bottom: 20px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/OrdersSider/OrdersSider.less"],"names":[],"mappings":"AAIA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;AAHD;AAMA;EACC,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;EACA,YAAA;EACA,uBAAA;AAJD;AAOA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;EACA,gBAAA;AALD;AAOC;EACC,yBAAA;EACA,kBAAA;AALF;AAQC;EACC,yBAAA;EACA,kBAAA;EACA,WAAA;AANF;AAUA;EACC,cAAA;EACA,OAAA;AARD;AAWA;EACC,cAAA;EACA,aAAA;EACA,yDAAA;AATD;AAYA;EACC,eAAA;AAVD;AAaA;EACC,gCAAA;EACA,kBAAA;AAXD;AAcA;EACC,aAAA;AAZD;AAWA;EAIE,kBAAA;AAZF;AAgBA;EACC,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AAdD;AAUA;EAOE,mBAAA;EACA,WAAA;AAdF","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n@import \"../../Styles/colors.less\";\n\n.orders-sider {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\tmax-width: 350px;\n\tmin-width: 210px;\n}\n\n.orders-sider-header {\n\talign-items: center;\n\tbackground: @primary;\n\tcolor: #ffffff;\n\tdisplay: flex;\n\tfont-size: 28px;\n\theight: 50px;\n\tjustify-content: center;\n}\n\n.orders-sider-body {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\toverflow-y: auto;\n\n\t&::-webkit-scrollbar-thumb {\n\t\tbackground-color: @gray-1;\n\t\tborder-radius: 5px;\n\t}\n\n\t&::-webkit-scrollbar {\n\t\tbackground-color: #262a2e;\n\t\tborder-radius: 5px;\n\t\twidth: 10px;\n\t}\n}\n\n.orders-sider-content {\n\tcolor: #ffffff;\n\tflex: 1;\n}\n\n.date-picker {\n\tcolor: @light-gray-2;\n\toutline: none;\n\ttransition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n}\n\n.scan-date-picker {\n\tmargin-top: 5px;\n}\n\n.sider-block {\n\tborder-bottom: 2px solid @gray-6;\n\tpadding: 15px 25px;\n}\n\n.range-inputs {\n\tdisplay: flex;\n\n\t.cec-input-wrapper:first-child {\n\t\tmargin-right: 10px;\n\t}\n}\n\n.orders-sider-footer {\n\talign-items: center;\n\tdisplay: flex;\n\tflex-direction: column;\n\tpadding: 20px 25px;\n\n\tbutton {\n\t\tmargin-bottom: 20px;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
