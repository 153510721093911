// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-management-modal {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 80vw;
}
.user-management-modal .cec-modal-title span:first-child {
  margin-right: 10px;
}
.user-management-modal .cec-modal-content {
  flex: 1;
  overflow-y: auto;
}
.user-management-modal .user-management-toolbar {
  display: flex;
}
.user-management-modal .user-management-toolbar .user-management-filters {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-left: 20px;
}
.user-management-modal .user-management-toolbar .user-management-filters .user-management-filter {
  align-items: center;
  display: flex;
}
.user-management-modal .user-management-toolbar .user-management-filters .user-management-filter:first-child {
  margin-right: 10px;
}
.user-management-modal .user-management-toolbar .user-management-filters .user-management-filter .label {
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UserManagementModal/UserManagementModal.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;AADD;AAHA;EAQG,kBAAA;AAFH;AANA;EAaE,OAAA;EACA,gBAAA;AAJF;AAVA;EAkBE,aAAA;AALF;AAbA;EAqBG,aAAA;EACA,OAAA;EACA,yBAAA;EACA,iBAAA;AALH;AAnBA;EA2BI,mBAAA;EACA,aAAA;AALJ;AAOI;EACC,kBAAA;AALL;AA1BA;EAmCK,iBAAA;AANL","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.user-management-modal {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmax-height: 90vh;\n\tmax-width: 80vw;\n\n\t.cec-modal-title {\n\t\tspan:first-child {\n\t\t\tmargin-right: 10px;\n\t\t}\n\t}\n\n\t.cec-modal-content {\n\t\tflex: 1;\n\t\toverflow-y: auto;\n\t}\n\n\t.user-management-toolbar {\n\t\tdisplay: flex;\n\n\t\t.user-management-filters {\n\t\t\tdisplay: flex;\n\t\t\tflex: 1;\n\t\t\tjustify-content: flex-end;\n\t\t\tmargin-left: 20px;\n\n\t\t\t.user-management-filter {\n\t\t\t\talign-items: center;\n\t\t\t\tdisplay: flex;\n\n\t\t\t\t&:first-child {\n\t\t\t\t\tmargin-right: 10px;\n\t\t\t\t}\n\n\t\t\t\t.label {\n\t\t\t\t\tmargin-right: 5px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
