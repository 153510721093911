import "Components/CreateUserModal/CreateUserModal.less";
import React, { Fragment, FormEvent, memo, useState, useRef } from "react";
import { Button, ButtonTypes, Checkbox, Input, Modal, Select } from "@clintonelec/react-storybook";
import Icon, { IconSize, IconWeight } from "Components/Icon";
import { IUser, IUserWithPassword } from "Interfaces";
import { createUserAction, updateUserAction } from "Data/Actions/User";
import { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { NotificationType, notify } from "src/Notifications";
import { escapeRegExp } from "lodash";
import { getFilters } from "Data/Selectors/UI";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";

interface ICreateUserModalProps {
	children?: React.ReactNode;
	user?: IUser;
}

interface IFormFields extends HTMLFormElement {
	userId: HTMLInputElement;
	realName: HTMLInputElement;
	email: HTMLInputElement;
	userRole: HTMLSelectElement;
	password?: HTMLInputElement;
	confirmPassword?: HTMLInputElement;
	status?: HTMLInputElement;
}

const CreateUserModal = (props: ICreateUserModalProps) => {
	const { children, user } = props;
	const [ password, setPassword ] = useState("");
	const [ resetPassword, setResetPassword ] = useState(false);
	const [ submitted, setSubmitted ] = useState(false);
	const [ visible, setVisible ] = useState(false);
	const formRef = useRef<HTMLFormElement>(null);
	const filters = useAppSelector(getFilters);
	const dispatch = useAppDispatch();
	const createUser = (newUser: IUserWithPassword) => dispatch(createUserAction(newUser));
	const updateUser = (updatedUser: IUserWithPassword) => dispatch(updateUserAction(updatedUser));

	const renderTitle = () => {
		if (user) {
			return (
				<Fragment>
					<Icon
						fixedWidth
						name="user-pen"
						iconWeight={ IconWeight.LIGHT }
					/>
					<span>Edit User</span>
				</Fragment>
			);
		}

		return (
			<Fragment>
				<Icon
					fixedWidth
					name="user-plus"
					iconWeight={ IconWeight.LIGHT }
				/>
				<span>Create User</span>
			</Fragment>
		);
	};

	const renderModalContent = () => {
		const { USER_ROLE } = filters;
		const roleOptions = [ { value: "Admin", label: "Admin" }, { value: "User", label: "User" } ];
		let defaultRoleOption = USER_ROLE;

		if (user) {
			defaultRoleOption = user?.admin ? "Admin" : "User";
		}

		return (
			<form
				onSubmit={ handleSubmit }
				ref={ formRef }
				noValidate
			>
				<div className="input-block">
					<div>User ID</div>
					<Input
						name="userId"
						required
						validityMessage={ submitted ? "User ID is required" : "" }
						defaultValue={ user?.username }
						autoComplete="new-password"
						noValidate={ !submitted }
					/>
				</div>
				<div className="input-block">
					<div>Name</div>
					<Input
						name="realName"
						required
						validityMessage={ submitted ? "Name is required" : "" }
						defaultValue={ user?.name }
						noValidate={ !submitted }
					/>
				</div>
				<div className="input-block">
					<div>Email</div>
					<Input
						name="email"
						defaultValue={ user?.email }
					/>
				</div>
				<div className="input-block">
					<div>Role</div>
					<Select
						name="userRole"
						defaultValue={ defaultRoleOption }
						options={ roleOptions }
						allowClear={ false }
					/>
				</div>
				{ renderPasswordFields() }
				{ renderEnabledCheckbox() }
				<Button icon={ { name: "check", size: IconSize.SMALLEST } }>
					Submit
				</Button>
			</form>
		);
	};

	const renderPasswordFields = () => {
		if (user && !resetPassword) {
			return (
				<Button
					type={ ButtonTypes.SECONDARY }
					ghost
					onClick={ handleClickResetPassword }
					icon={ { name: "arrow-rotate-left", size: IconSize.SMALLEST } }
				>
					Reset Password
				</Button>
			);
		}

		return (
			<Fragment>
				<div className="input-block">
					<div>Enter Password</div>
					<Input
						name="password"
						required
						password
						onUpdate={ setPassword }
						validityMessage={ "Password is required" }
						noValidate={ !submitted }
						autoComplete="new-password"
					/>
				</div>
				<div className="input-block">
					<div>Confirm Password</div>
					<Input
						name="confirmPassword"
						required
						password
						validator={ `^${ escapeRegExp(password) }$` }
						validityMessage={ "Password is required and must match" }
						noValidate={ !submitted }
						autoComplete="new-password"
					/>
				</div>
			</Fragment>
		);
	};

	const renderEnabledCheckbox = () => {
		if (!user) {
			return null;
		}

		return (
			<Checkbox name="status" defaultChecked={ user?.active ?? true }>
				Enabled
			</Checkbox>
		);
	};

	const handleSubmit = (event: FormEvent<IFormFields>) => {
		event.preventDefault();

		setSubmitted(true);

		if (formRef.current.checkValidity()) {
			const action = user ? updateUser : createUser;
			const verb = user ? "Updating" : "Creating";
			const username = event.currentTarget.userId.value;
			const name = event.currentTarget.realName.value;
			const email = event.currentTarget.email.value;
			const admin = event.currentTarget.userRole.value === "Admin" ? true : false;
			const newPassword = event.currentTarget.password?.value;
			const passwordConfirmation = event.currentTarget.confirmPassword?.value;
			const active = event.currentTarget.status?.checked;
			const constructedUser: IUserWithPassword = {
				id: user?.id ?? undefined,
				username,
				name,
				email,
				active,
				admin,
				password: newPassword,
				passwordConfirmation
			};

			return action(constructedUser)
				.then((result: PayloadAction<IUser, null, null, SerializedError>) => {
					if (result.error) {
						return notify(NotificationType.ERROR, `Error ${ verb } User`, result?.error?.message);
					}

					setVisible(!visible);
				}).catch((error) => {
					return notify(NotificationType.ERROR, `Error ${ verb } User`, error);
				});
		}
	};

	const handleClickResetPassword = () => {
		setResetPassword(true);
	};

	const resetState = () => {
		setSubmitted(false);
		setResetPassword(false);
	};

	return (
		<Modal
			title={ renderTitle() }
			modalContent={ renderModalContent() }
			width="270px"
			visible={ visible }
			onVisibilityChange={ setVisible }
			className="create-user-modal"
			afterClose={ resetState }
		>
			{ children }
		</Modal>
	);
};

export default memo(CreateUserModal);
