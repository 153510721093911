import { IIndexSignature } from "Interfaces";

export class IPaginatedDataSet<T> {
	data: T[];
	lastPage: number;
	options: IIndexSignature<string | number>;
}

export interface IDataPage<T> {
	data: T[];
	links: {
		first: string;
		last: string;
		prev: string;
		next: string;
		self: string;
	};
}
