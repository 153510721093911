import { createListenerMiddleware } from "@reduxjs/toolkit";
import { appRehydratedListener } from "Data/Redux/Middleware/Listener/System";

const listenerMiddleware = createListenerMiddleware();

[
	appRehydratedListener
].forEach((listener) => {
	listener(listenerMiddleware);
});

export default listenerMiddleware;
