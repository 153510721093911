import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { UsersState } from "Data/Objects/AppState";
import { IApiUser, IUser } from "Interfaces";
import {
	createUserAction, fetchNextUserPage, loginUserAsync, logoutUserAsync, updateUserAction
} from "Data/Actions/User";
import { createPaginatedItem, handleClearCache, handleDataPage, updatePaginatedItem } from "Data/Utils/Redux";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";

const { CLEAR_CACHE } = ACTION_TYPES.System;
const defaultState: UsersState = {
	data: [],
	lastPage: -1,
	options: {
		"page[size]": 15,
		"page[number]": 1
	},
	user: null,
	token: null
};

export const loginUser = (state: UsersState, action: PayloadAction<IApiUser>) => {
	const { user, accessToken } = action?.payload ?? {};

	if (user && accessToken) {
		state.user = user;
		state.token = accessToken;
	}
};

export const logoutUser = (state: UsersState) => {
	state.user = null;
	state.token = null;
};

export const handleUpdateUser = (state: UsersState, action: PayloadAction<IUser>) => {
	updatePaginatedItem(state, action);

	if (action.payload?.id === state.user.id) {
		state.user = action.payload;
	}
};

const reducers = (reducerBuilder) => {
	reducerBuilder.addCase(CLEAR_CACHE, handleClearCache(defaultState));
	reducerBuilder.addCase(loginUserAsync.fulfilled, loginUser);
	reducerBuilder.addCase(logoutUserAsync.fulfilled, logoutUser);
	reducerBuilder.addCase(fetchNextUserPage.fulfilled, handleDataPage);
	reducerBuilder.addCase(updateUserAction.fulfilled, handleUpdateUser);
	reducerBuilder.addCase(createUserAction.fulfilled, createPaginatedItem);
};

export default createReducer(defaultState, reducers);
