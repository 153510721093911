import { GET } from "Data/API/Request";
import { deserializeJsonApiData } from "Data/Utils/Json";
import { IIndexSignature, IItem, IWithApiData } from "Interfaces";

export const getItems = (params?: IIndexSignature<string | number>) => {
	return GET("items", { ...params, include: "updater,order" })
		.then((response: IWithApiData) => {
			return deserializeJsonApiData<Partial<IItem>[]>(response).then((items: Partial<IItem>[]) => {
				return { ...response, data: items };
			});
		});
};

export const getItem = (id: string) => {
	return GET(`items/${ id }`)
		.then((response: IWithApiData) => {
			return deserializeJsonApiData<Partial<IItem>>(response);
		});
};
