// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-wrapper {
  flex: 1;
  overflow-y: auto;
}
.table-wrapper .status-icon {
  margin-right: 10px;
}
.table-wrapper .downloaded {
  color: #33B889;
}
.table-wrapper .not-downloaded {
  color: #fd4445;
}
.table-wrapper .downloading {
  color: #f4ce34;
}
.table-wrapper .loader-container {
  display: flex;
  justify-content: center;
}
.table-wrapper .loader-container .loader {
  animation: spin 1s linear infinite;
  border: 1vh solid #f3f3f3;
  border-radius: 50%;
  border-top: 1vh solid #127ec0;
  height: 10vh;
  width: 10vh;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.table-wrapper .cec-table .cec-table-container table tbody > tr > td {
  padding: 0 0 0 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ItemTable/ItemTable.less"],"names":[],"mappings":"AAEA;EACC,OAAA;EACA,gBAAA;AADD;AADA;EAKE,kBAAA;AADF;AAJA;EASE,cAAA;AAFF;AAPA;EAaE,cAAA;AAHF;AAVA;EAiBE,cAAA;AAJF;AAbA;EAqBE,aAAA;EACA,uBAAA;AALF;AAjBA;EAyBG,kCAAA;EACA,yBAAA;EACA,kBAAA;EACA,6BAAA;EACA,YAAA;EACA,WAAA;AALH;AAQE;EACC;IAAK,uBAAA;EALN;EAMC;IAAO,yBAAA;EAHR;AACF;AAjCA;EAwCE,mBAAA;AAJF","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.table-wrapper {\n\tflex: 1;\n\toverflow-y: auto;\n\n\t.status-icon {\n\t\tmargin-right: 10px;\n\t}\n\n\t.downloaded {\n\t\tcolor: @success;\n\t}\n\n\t.not-downloaded {\n\t\tcolor: @error;\n\t}\n\n\t.downloading {\n\t\tcolor: @warning;\n\t}\n\n\t.loader-container {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\n\t\t.loader {\n\t\t\tanimation: spin 1s linear infinite;\n\t\t\tborder: 1vh solid #f3f3f3;\n\t\t\tborder-radius: 50%;\n\t\t\tborder-top: 1vh solid #127ec0;\n\t\t\theight: 10vh;\n\t\t\twidth: 10vh;\n\t\t}\n\n\t\t@keyframes spin {\n\t\t\t0% { transform: rotate(0deg); }\n\t\t\t100% { transform: rotate(360deg); }\n\t\t}\n\t}\n\n\t.cec-table .cec-table-container table tbody > tr > td {\n\t\tpadding: 0 0 0 16px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
