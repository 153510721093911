import { dom, library } from "@fortawesome/fontawesome-svg-core";
import * as light from "@fortawesome/pro-light-svg-icons";
import * as regular from "@fortawesome/pro-regular-svg-icons";
import * as solid from "@fortawesome/pro-solid-svg-icons";

// Add our solid, regular, and light icons to the library
export const icons = [
	"faMagnifyingGlass", "faEraser", "faUser", "faSignOut", "faSignIn", "faEyeSlash", "faEye", "faCaretUp",
	"faCaretDown", "faInbox", "faExclamationCircle", "faCheckCircle", "faTimesCircle", "faUsers", "faTimes",
	"faUserPlus", "faUserPen", "faCheck", "faArrowRotateLeft", "faPencil", "faXmark", "faEnvelope", "faLockKeyhole",
	"faDownload", "faEllipsisH", "faAngleDoubleRight", "faAngleDoubleLeft", "faChevronRight", "faChevronLeft"
];

export default function initFontAwesome() {
	icons.map((icon: string) => {
		library.add(solid[icon]);
		library.add(regular[icon]);
		library.add(light[icon]);
	});

	dom.watch();
}
