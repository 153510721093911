import { createAction } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ISetFilterPayload, ScanDateFilterOptions } from "Interfaces";

const {
	CLEAR_ORDER_FORM, SET_SCAN_DATE_OPTION, SET_SELECTED_ITEMS, SET_FILTER
} = ACTION_TYPES.UI;

export const clearOrderFormAction = createAction(CLEAR_ORDER_FORM);

export const setSelectedItemsAction = createAction<string[]>(SET_SELECTED_ITEMS);

export const setScanDateOptionAction = createAction<ScanDateFilterOptions>(SET_SCAN_DATE_OPTION);

export const setFilterAction = createAction<ISetFilterPayload>(SET_FILTER);
