import Pusher from "pusher-js";
import { isProduction } from "Data/Objects/System";
import { ActionCreatorWithOptionalPayload, AsyncThunk, AsyncThunkAction } from "@reduxjs/toolkit";
import { store } from "Data/Redux/Store";
import { AppState } from "Data/Objects/AppState";
import { IPusherEvent, IWithApiData } from "Interfaces";
import { createUserLocal, updateUserLocal } from "Data/Actions/User";
import { createOrderAndFetchItems, deleteOrderLocal, updateOrderAndFilterItems } from "Data/Actions/Pusher";

const deleteWrapper = <T, V>(actionCreator: AsyncThunk<T, string, V>) => {
	return (notification: IWithApiData) => {
		return actionCreator(notification.data.id);
	};
};

type EventAction = AsyncThunk<unknown, unknown, unknown>
	| ((data: IWithApiData) => AsyncThunkAction<unknown, unknown, unknown>)
	| ActionCreatorWithOptionalPayload<unknown>;

interface IEventBindings {
	[type: string]: {
		[event: string]: {
			action?: EventAction;
			callback?: () => void;
			channel?: {
				[channel: string]: EventAction;
			};
		};
	};
}

const EVENT_BINDINGS: IEventBindings = {
	order: {
		created: {
			action: createOrderAndFetchItems
		},
		updated: {
			action: updateOrderAndFilterItems
		},
		deleted: {
			action: deleteWrapper(deleteOrderLocal)
		}
	},
	user: {
		created: {
			action: createUserLocal
		},
		updated: {
			action: updateUserLocal
		}
	}
};

export class PusherConnection {
	pusherConnection: Pusher.Pusher;
	globalChannel: Pusher.Channel;

	initializeConnection = () => {
		this.connectPusher();
		this.connectGlobalChannel();
	};

	connectPusher = () => {
		const state: AppState = store.getState();
		const { User: { user, token } } = state;
		const pusherkey = isProduction ? "Zg26Zj9l5TACPDqdnk3W" : "ePCIVAHhz5wHtqNtHjdx";
		const authEndpoint = isProduction
			? "https://nephele.clintonelectronics.com/api/broadcasting/auth"
			: "https://develop.nephele.clintonelectronics.com/api/broadcasting/auth";

		if (user && !this.pusherConnection) {
			this.pusherConnection = new Pusher(pusherkey, {
				authEndpoint: authEndpoint,
				auth: {
					headers: {
						"Authorization": "Bearer " + token
					}
				},
				disableStats: true,
				enabledTransports: [ "ws", "wss" ],
				encrypted: true,
				forceTLS: true,
				wsHost: "conloquium.clintonconnect.com"
			});
		}
	};

	rebindEvents = () => {
		this.connectPusher();
		this.connectGlobalChannel(true);
	};

	connectGlobalChannel = (reset?: boolean) => {
		const channelName = "global";

		if (!this.globalChannel || this.globalChannel.name !== channelName) {
			this.pusherConnection.allChannels().forEach((channel: Pusher.Channel) => {
				if (channel.name.indexOf("global") >= 0) {
					this.pusherConnection.unsubscribe(channel.name);
				}
			});

			this.globalChannel = this.pusherConnection.subscribe(channelName);
		}

		if (!this.globalChannel.subscribed || reset) {
			this.globalChannel.unbind_global();
			this.globalChannel.bind_global(this.createPusherHandler(channelName));
		}
	};

	createPusherHandler = (channel: string) => {
		return (eventType: string, notification: IPusherEvent) => {
			if (!notification || !channel) {
				return;
			}

			const channelNotification = { Channel: channel, Notification: { ...notification } };

			console.log(channelNotification);

			const actionCreator =
				EVENT_BINDINGS?.[notification.type]?.[notification.event]?.channel?.[channel]
				?? EVENT_BINDINGS?.[notification.type]?.[notification.event]?.action;

			const callback = EVENT_BINDINGS?.[notification.type]?.[notification.event]?.callback;

			if (actionCreator) {
				notification.entities.forEach((id: string) => {
					if (notification.batch) {
						notification.data.forEach(data => {
							Promise.resolve(store.dispatch(actionCreator(
								{ data: { ...data } }
							))).then(callback);
						});
					} else {
						Promise.resolve(store.dispatch(actionCreator(
							{ data: { ...notification.data, id } }
						))).then(callback);
					}
				});
			}
		};
	};

	disconnect() {
		this.pusherConnection.allChannels().map((channel: Pusher.Channel) => {
			this.pusherConnection.unsubscribe(channel.name);
		});
		this.pusherConnection.unbind_all();
		this.pusherConnection.disconnect();

		delete this.pusherConnection;
		delete this.globalChannel;
	}
}

export const pusherConnection = new PusherConnection();
