import { FormEvent, Fragment, memo, useRef, useState } from "react";
import ClintonElecSVG from "Images/Clinton_Logo.svg";
import { Button, ButtonTypes, Input } from "@clintonelec/react-storybook";
import "Components/Login/LoginForm/LoginForm.less";
import { loginUserAsync } from "Data/Actions/User";
import { PayloadAction } from "@reduxjs/toolkit";
import { NotificationType, notify } from "src/Notifications";
import { pusherConnection } from "src/Pusher";
import { forgotPassword as forgotPasswordApi } from "Data/API/User";
import { getPartNumbersAction } from "Data/Actions/PartNumbers";
import { IconSize } from "Components/Icon";
import { useAppDispatch } from "Data/Redux/Store";

interface IForgotPasswordFormFields extends HTMLFormElement {
	email: HTMLInputElement;
}

const LoginForm = () => {
	const [ forgotPassword, setForgotPassword ] = useState(false);
	const [ submitted, setSubmitted ] = useState(false);
	const formRef = useRef<HTMLFormElement>(null);
	const dispatch = useAppDispatch();
	const attemptLogin = (username: string, password: string) => dispatch(loginUserAsync({ username, password }));
	const getPartNumbers = () => dispatch(getPartNumbersAction());

	const onLoginSubmit = (event) => {
		event.preventDefault();

		if (!attemptLogin) {
			return;
		}

		setSubmitted(true);

		if (formRef.current.checkValidity()) {
			attemptLogin(event?.target?.username?.value, event?.target?.password?.value)
				.then((result: PayloadAction<null, null, null, string>) => {
					if (result.error) {
						notify(NotificationType.ERROR, "Login Error", "The user credentials were incorrect");
					} else {
						getPartNumbers();
						pusherConnection.initializeConnection();
					}
				});
		}
	};

	const onForgotPasswordSubmit = (event: FormEvent<IForgotPasswordFormFields>) => {
		event.preventDefault();

		if (formRef.current.checkValidity()) {
			forgotPasswordApi(event.currentTarget?.email?.value)
				.then(() => {
					notify(NotificationType.SUCCESS, "Account Recovery Email Sent");

					toggleForgotPassword();
				})
				.catch((error) => {
					notify(NotificationType.ERROR, "Error Sending Account Recovery Email", error);
				});
		}

		setSubmitted(false);
	};

	const toggleForgotPassword = () => {
		setForgotPassword(!forgotPassword);
	};

	const renderContent = () => {
		if (forgotPassword) {
			return (
				<Fragment>
					<form onSubmit={ onForgotPasswordSubmit } ref={ formRef }>
						<label>Email</label>
						<Input
							name="email"
							required
							validityMessage={ submitted ? "Email is required" : undefined }
						/>
						<Button
							id="login-button"
							className="login-button"
							type={ ButtonTypes.PRIMARY }
							htmlType="submit"
							icon={ {
								name: "envelope",
								size: IconSize.SMALLER
							} }
						>
							Send Recovery Link
						</Button>
					</form>
					<div className="forgot-password" onClick={ toggleForgotPassword }>Return to Log in page</div>
				</Fragment>
			);
		}

		return (
			<Fragment>
				<form onSubmit={ onLoginSubmit } ref={ formRef } noValidate>
					<label>Username</label>
					<Input
						name="username"
						wrapClassName="login-input-username"
						required
						noValidate={ !submitted }
						validityMessage={ "Email is required" }
					/>
					<label>Password</label>
					<Input
						name="password"
						wrapClassName="login-input"
						password
						required
						noValidate={ !submitted }
						validityMessage={ "Password is required" }
					/>
					<Button
						id="login-button"
						className="login-button"
						type={ ButtonTypes.PRIMARY }
						htmlType="submit"
						icon={ {
							name: "sign-in",
							size: IconSize.SMALLER
						} }
					>
						Sign In
					</Button>
				</form>
				<div className="forgot-password" onClick={ toggleForgotPassword }>Forgot Your Password?</div>
			</Fragment>
		);
	};

	return (
		<div className="login-form">
			<img src={ ClintonElecSVG } className="logo" />
			{ renderContent() }
		</div>
	);
};

export default memo(LoginForm);
