import { ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { AppState } from "Data/Objects/AppState";
import { appRehydratedPredicate } from "Data/Redux/Middleware/Listener/Predicates/System";
import { pusherConnection } from "src/Pusher";

export const appRehydratedListener = (listenerMiddleware: ListenerMiddlewareInstance<AppState>) => {
	listenerMiddleware.startListening(
		{
			predicate: appRehydratedPredicate,
			effect: async (action, { getState }) => {
				const newState = getState();
				const { user, token } = newState.User;

				if (user && token) {
					pusherConnection.initializeConnection();
				}
			}
		}
	);
};
