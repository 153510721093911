// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/@clintonelec/react-storybook/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  color: rgba(0 0 0 / 65%);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: "tnum";
  font-size: 14px;
  font-variant: tabular-nums;
  height: 100%;
  line-height: 1.5715;
  margin: 0;
}
#app {
  height: 100%;
  width: 100%;
}
.app-window {
  display: flex;
  height: 100%;
  width: 100%;
}
.main-layout {
  height: 100%;
}
.cec-sider {
  position: relative;
  transition: all 0.2s;
}
.left-sidebar {
  background-color: #272a35;
}
.content-area {
  display: flex;
  flex: 2;
  flex-direction: column;
}
.selectable-text {
  color: #127ec0;
  cursor: pointer;
}
.selectable-text:hover {
  color: #3fabed;
}
.new-table-data-notification {
  margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/global.less","webpack://./src/Styles/index.less"],"names":[],"mappings":"AAIA;;;EAGC,sBAAA;ACFD;ADKA;EACC,YAAA;ACHD;ADMA;EACC,wBAAA;EACA,kMAAA;EAaA,6BAAA;EACA,eAAA;EACA,0BAAA;EACA,YAAA;EACA,mBAAA;EACA,SAAA;AChBD;ADmBA;EACC,YAAA;EACA,WAAA;ACjBD;ADoBA;EACC,aAAA;EACA,YAAA;EACA,WAAA;AClBD;ADqBA;EACC,YAAA;ACnBD;ADsBA;EACC,kBAAA;EACA,oBAAA;ACpBD;ADuBA;EACC,yBAAA;ACrBD;ADwBA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;ACtBD;ADyBA;EACC,cAAA;EACA,eAAA;ACvBD;ADyBC;EACC,cAAA;ACvBF;AD2BA;EACC,mBAAA;ACzBD","sourcesContent":["@import \"./colors.less\";\n\n@sider-gray: @gray-3;\n\n*,\n*::before,\n*::after {\n\tbox-sizing: border-box;\n}\n\nhtml {\n\theight: 100%;\n}\n\nbody {\n\tcolor: rgba(0 0 0 / 65%);\n\tfont-family:\n\t\t-apple-system,\n\t\tBlinkMacSystemFont,\n\t\t\"Segoe UI\",\n\t\tRoboto,\n\t\t\"Helvetica Neue\",\n\t\tArial,\n\t\t\"Noto Sans\",\n\t\tsans-serif,\n\t\t\"Apple Color Emoji\",\n\t\t\"Segoe UI Emoji\",\n\t\t\"Segoe UI Symbol\",\n\t\t\"Noto Color Emoji\";\n\tfont-feature-settings: \"tnum\";\n\tfont-size: 14px;\n\tfont-variant: tabular-nums;\n\theight: 100%;\n\tline-height: 1.5715;\n\tmargin: 0;\n}\n\n#app {\n\theight: 100%;\n\twidth: 100%;\n}\n\n.app-window {\n\tdisplay: flex;\n\theight: 100%;\n\twidth: 100%;\n}\n\n.main-layout {\n\theight: 100%;\n}\n\n.cec-sider {\n\tposition: relative;\n\ttransition: all 0.2s;\n}\n\n.left-sidebar {\n\tbackground-color: @sider-gray;\n}\n\n.content-area {\n\tdisplay: flex;\n\tflex: 2;\n\tflex-direction: column;\n}\n\n.selectable-text {\n\tcolor: @blue-1;\n\tcursor: pointer;\n\n\t&:hover {\n\t\tcolor: @blue-0;\n\t}\n}\n\n.new-table-data-notification {\n\tmargin-bottom: 2rem;\n}\n","@import (reference) \"colors\";\n\n@import \"~@clintonelec/react-storybook/styles.css\";\n@import \"./global.less\";\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
