// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-management-modal .table-wrapper {
  height: 100%;
  margin: 10px 0 0;
  padding: 0;
}
.user-management-modal .table-wrapper tbody > tr > td:nth-child(5) {
  text-align: center;
}
.user-management-modal .table-wrapper .active-status {
  color: #33B889;
}
.user-management-modal .table-wrapper .inactive-status {
  color: #fd4445;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UserManagementTable/UserManagementTable.less"],"names":[],"mappings":"AAIA;EAEE,YAAA;EACA,gBAAA;EACA,UAAA;AAJF;AAAA;EAOG,kBAAA;AAJH;AAHA;EAWG,cAAA;AALH;AANA;EAeG,cAAA;AANH","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n@import \"../../Styles/colors.less\";\n\n.user-management-modal {\n\t.table-wrapper {\n\t\theight: 100%;\n\t\tmargin: 10px 0 0;\n\t\tpadding: 0;\n\n\t\ttbody > tr > td:nth-child(5) {\n\t\t\ttext-align: center;\n\t\t}\n\n\t\t.active-status {\n\t\t\tcolor: @green-1;\n\t\t}\n\n\t\t.inactive-status {\n\t\t\tcolor: @red-1;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
