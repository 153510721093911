import { ISelectOption } from "@clintonelec/react-storybook";
import { createSelector } from "@reduxjs/toolkit";
import { AppState, PartNumbersState } from "Data/Objects/AppState";

export const getPartNumbersState = (appState: AppState) => {
	return appState?.PartNumbers;
};

export const getParts = (state: PartNumbersState) => {
	return state.partNumbers;
};

export const getPartNumbersOptions = (partNumbers: string[]) => {
	return partNumbers.map((partNumber) => {
		return { label: partNumber, value: partNumber } as ISelectOption;
	});
};

export const getPartNumbers = createSelector(
	getPartNumbersState,
	getParts
);

export const getPartNumbersSelectOptions = createSelector(
	getPartNumbers,
	getPartNumbersOptions
);
