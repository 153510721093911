import { createSelector } from "@reduxjs/toolkit";
import { AppState, ItemsState } from "Data/Objects/AppState";
import { getItemFilters } from "Data/Selectors/UI";
import { createIdentitySelector, filterPaginatedDataById, getPaginatedDataOptions } from "Data/Utils/Redux";
import { IItem, IItemFilters } from "Interfaces";

export const getItemsState = (appState: AppState) => {
	return appState?.Items;
};

const getFetchable = (state: ItemsState) => {
	const { lastPage, options } = state;
	const hasLookupFilters = Object.keys(options).length > 2;
	const nextPageExists = lastPage === -1 || +options["page[number]"] <= lastPage;

	return hasLookupFilters && nextPageExists;
};

const getItems = (state: ItemsState) => {
	const { options } = state;
	const hasLookupFilters = Object.keys(options).length > 2;

	if (!hasLookupFilters) {
		return [];
	}

	return state?.data;
};

const getProgress = (state: ItemsState) => {
	const { lastPage, options } = state;

	return (+options["page[number]"]) / lastPage * 100;
};

const getFiltered = (filters: IItemFilters, items: Partial<IItem>[]) => {
	return items.filter((item) => {
		const { serialNumber, scannedAt, order } = item;
		const {
			scannedAfter, scannedBefore, serialDateUpper, serialDateLower, serialRangeLower,
			serialRangeUpper, serialNumber: serialNumberFilter, orderNumber: orderNumberFilter,
			selectedPartNumbers: partNumbers
		} = filters;

		const hasOrderFilter = orderNumberFilter
			? order.orderNumber.toLowerCase().includes(orderNumberFilter.toString().toLowerCase())
			: true;

		const hasSerialNumberFilter = serialNumberFilter
			? serialNumber.toLowerCase().includes(serialNumberFilter.toString().toLowerCase())
			: true;

		const matchesPartNumberFilter = partNumbers.length > 0
			? partNumbers.some((partNumber) => partNumber.toLowerCase().includes(item.partNumber.toLowerCase()))
			: true;

		// serial range
		const serialRange = +serialNumber.substring(serialNumber.length - 4);
		const lowerRange = serialRangeLower ? +serialRangeLower : serialRange;
		const upperRange = serialRangeUpper ? +serialRangeUpper : serialRange;
		// scan date
		const scannedAtDate = new Date(scannedAt);
		const isScannedAfter = scannedAfter ? scannedAtDate >= new Date(scannedAfter) : true;
		const isScannedBefore = scannedBefore ? scannedAtDate <= new Date(scannedBefore) : true;
		const isInScanDateRange = isScannedAfter && isScannedBefore;
		// serial date
		const serialDate = new Date(serialNumber.substring(0, 6).replace(/(.{2})/g, "$1/"));
		const lowerDate = serialDateLower ? new Date(serialDateLower) : serialDate;
		const upperDate = serialDateUpper ? new Date(serialDateUpper) : serialDate;

		return serialDate >= lowerDate && serialDate <= upperDate
			&& serialRange >= lowerRange && serialRange <= upperRange && isInScanDateRange && hasOrderFilter
			&& hasSerialNumberFilter && matchesPartNumberFilter;
	});
};

export const getAllItems = createSelector(
	getItemsState,
	getItems
);

export const getFilteredItems = createSelector(
	[ getItemFilters, createIdentitySelector<Partial<IItem>[]>() ],
	getFiltered
);

export const getItemsFetchable = createSelector(
	getItemsState,
	getFetchable
);

export const getItemById = createSelector(
	getItemsState,
	createIdentitySelector<string>(),
	filterPaginatedDataById<IItem>
);

export const getItemsOptions = createSelector(
	getItemsState,
	getPaginatedDataOptions
);

export const getItemsProgress = createSelector(
	getItemsState,
	getProgress
);
