import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { UIState } from "Data/Objects/AppState";
import { UserRoles, UserStatus } from "Data/Objects/UI";
import { handleClearCache } from "Data/Utils/Redux";
import { ISetFilterPayload, ScanDateFilterOptions } from "Interfaces";

const { CLEAR_CACHE } = ACTION_TYPES.System;
const {
	CLEAR_ORDER_FORM, SET_SCAN_DATE_OPTION, SET_SELECTED_ITEMS, SET_FILTER
} = ACTION_TYPES.UI;

const defaultState: UIState = {
	selectedItems: [],
	scanDateOption: null,
	filters: {
		USER_ROLE: UserRoles.USER,
		USER_STATUS: UserStatus.ACTIVE
	}
};

export const setScanDateOptionReducer = (state: UIState, action: PayloadAction<ScanDateFilterOptions>) => {
	state.scanDateOption = action?.payload;
};

export const clearOrderFormState = (state: UIState) => {
	state.scanDateOption = null;
};

export const setSelectedItems = (state: UIState, action: PayloadAction<string[]>) => {
	state.selectedItems = action?.payload;
};

export const setFilterReducer = (state: UIState, action: PayloadAction<ISetFilterPayload>) => {
	const { filter, value } = action?.payload ?? {};

	if (filter) {
		state.filters[ filter ] = value;
	}
};

const reducers = (reducerBuilder) => {
	reducerBuilder.addCase(CLEAR_ORDER_FORM, clearOrderFormState);
	reducerBuilder.addCase(SET_SCAN_DATE_OPTION, setScanDateOptionReducer);
	reducerBuilder.addCase(SET_SELECTED_ITEMS, setSelectedItems);
	reducerBuilder.addCase(CLEAR_CACHE, handleClearCache(defaultState));
	reducerBuilder.addCase(SET_FILTER, setFilterReducer);
};

export default createReducer(defaultState, reducers);
