// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-password-form {
  top: 2vh;
  width: 100%;
}
.forgot-password-form .logo {
  background-color: #127ec0;
  height: 3em;
  margin-bottom: 1vh;
  padding: 10px;
  top: -2.75em;
  width: 100%;
}
.forgot-password-form .submit-button {
  margin-top: 1em;
  width: 100%;
}
.forgot-password-form .not-last-input {
  margin-bottom: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Login/ForgotPasswordForm/ForgotPasswordForm.less"],"names":[],"mappings":"AAEA;EACC,QAAA;EACA,WAAA;AADD;AADA;EAKE,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;AADF;AATA;EAcE,eAAA;EACA,WAAA;AAFF;AAbA;EAmBE,kBAAA;AAHF","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.forgot-password-form {\n\ttop: 2vh;\n\twidth: 100%;\n\n\t.logo {\n\t\tbackground-color: @primary;\n\t\theight: 3em;\n\t\tmargin-bottom: 1vh;\n\t\tpadding: 10px;\n\t\ttop: -2.75em;\n\t\twidth: 100%;\n\t}\n\n\t.submit-button {\n\t\tmargin-top: 1em;\n\t\twidth: 100%;\n\t}\n\n\t.not-last-input {\n\t\tmargin-bottom: 7px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
