// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-user-modal .cec-modal-title span:first-child {
  margin-right: 10px;
}
.create-user-modal form {
  display: flex;
  flex-direction: column;
}
.create-user-modal form .input-block:not(:first-child) {
  margin-top: 10px;
}
.create-user-modal form .cec-button {
  margin-top: 15px;
}
.create-user-modal form .cec-checkbox-container {
  margin-bottom: 0;
  margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CreateUserModal/CreateUserModal.less"],"names":[],"mappings":"AAEA;EAGG,kBAAA;AAHH;AAAA;EAQE,aAAA;EACA,sBAAA;AALF;AAJA;EAYG,gBAAA;AALH;AAPA;EAgBG,gBAAA;AANH;AAVA;EAoBG,gBAAA;EACA,gBAAA;AAPH","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.create-user-modal {\n\t.cec-modal-title {\n\t\tspan:first-child {\n\t\t\tmargin-right: 10px;\n\t\t}\n\t}\n\n\tform {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\n\t\t.input-block:not(:first-child) {\n\t\t\tmargin-top: 10px;\n\t\t}\n\n\t\t.cec-button {\n\t\t\tmargin-top: 15px;\n\t\t}\n\n\t\t.cec-checkbox-container {\n\t\t\tmargin-bottom: 0;\n\t\t\tmargin-top: 15px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
