import { clearCache } from "Data/Actions/System";
import { logoutUserLocal } from "Data/Actions/User";
import { pusherConnection } from "src/Pusher";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const disconnectOnLogout = store => next => action => {
	if (action.type === "LOGOUT_USER/pending") {
		pusherConnection.disconnect();
	}

	return next(action);
};

export const logoutOnUnauthorized = store => next => action => {
	if (action.type?.includes("rejected") && action.error.message === "401") {
		store.dispatch(logoutUserLocal());
		store.dispatch(clearCache());
	}

	return next(action);
};
