import LoginForm from "Components/Login/LoginForm";
import "Components/Login/LoginPage/LoginPage.less";
import ForgotPasswordForm from "Components/Login/ForgotPasswordForm";
import { memo } from "react";

interface ILoginPageProps {
	forgotPassword?: boolean;
}

const LoginPage = (props: ILoginPageProps) => {
	const { forgotPassword } = props;
	const copyrightYears = "2017-" + (new Date()).getFullYear();

	const renderContent = () => {
		if (forgotPassword) {
			return <ForgotPasswordForm />;
		}

		return <LoginForm />;
	};

	return (
		<div className="login-page">
			<div className="center-content">
				<div className="login-content">
					{ renderContent() }
				</div>
			</div>
			<div className="login-footer">
				<div className="footer-row footer-copyright">
					&copy; { copyrightYears } Clinton Electronics.
				</div>
			</div>
		</div>
	);
};

export default memo(LoginPage);
