import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { IIndexSignature, IItem, IPaginatedDataSet } from "Interfaces";
import { handleClearCache, handleDataPage } from "Data/Utils/Redux";
import { fetchNextItemsPageAction } from "Data/Actions/Items";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";

const { CLEAR_CACHE } = ACTION_TYPES.System;
const { SET_ITEMS_OPTIONS } = ACTION_TYPES.Items;
const { CLEAR_ORDER_FORM } = ACTION_TYPES.UI;
const defaultState: IPaginatedDataSet<IItem> = {
	data: [],
	lastPage: -1,
	options: {
		"page[size]": 200,
		"page[number]": 1
	}
};

export const setItemsOptionsReducer = (
	state: IPaginatedDataSet<IItem>, action: PayloadAction<IIndexSignature<string | number>>
) => {
	state.options = action?.payload;
	state.data = [];
	state.lastPage = -1;
};

const reducers = (reducerBuilder) => {
	reducerBuilder.addCase(fetchNextItemsPageAction.fulfilled, handleDataPage);
	reducerBuilder.addCase(SET_ITEMS_OPTIONS, setItemsOptionsReducer);
	reducerBuilder.addCase(CLEAR_CACHE, handleClearCache(defaultState));
	reducerBuilder.addCase(CLEAR_ORDER_FORM, handleClearCache(defaultState));
};

export default createReducer(defaultState, reducers);
