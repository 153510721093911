import { GET, PATCH, POST, DELETE } from "Data/API/Request";
import { deserializeJsonApiData, serializeJsonApiData } from "Data/Utils/Json";
import { IIndexSignature, ILoginUserArgs, IResetPasswordArgs, IUser, IWithApiData } from "Interfaces";

export const loginUser = (loginUserArgs: ILoginUserArgs) => {
	return POST("auth/login", loginUserArgs);
};

export const logoutUser = () => {
	return POST("auth/logout");
};

export const forgotPassword = (email: string) => {
	return POST("auth/forgot-password", { email });
};

export const resetPassword = (args: IResetPasswordArgs) => {
	return POST("auth/reset-password", { ...args });
};

export const getUsers = (params?: IIndexSignature<string | number>) => {
	return GET("users", { ...params }).then((response: IWithApiData) => {
		return deserializeJsonApiData<Partial<IUser>[]>(response).then((user: Partial<IUser>[]) => {
			return { ...response, data: user };
		});
	});
};

export const getUser = (id: string) => {
	return GET(`users/${ id }`)
		.then((response: IWithApiData) => {
			return deserializeJsonApiData<Partial<IUser>>(response);
		});
};

export const createUser = (user: IUser) => {
	return POST(
		"users",
		serializeJsonApiData("user", user)
	).then((response: IWithApiData) => {
		return deserializeJsonApiData<IUser>(response);
	});
};

export const updateUser = (user: Partial<IUser>) => {
	return PATCH(
		`users/${ user?.id }`,
		serializeJsonApiData("user", user)
	).then((response: IWithApiData) => {
		return deserializeJsonApiData<IUser>(response);
	});
};

export const deleteUser = (id: string) => {
	return DELETE(`users/${ id }`);
};
