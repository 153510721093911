// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  top: 2vh;
  width: 100%;
}
.login-form .forgot-password {
  color: #8e8e8e;
  cursor: pointer;
  margin-top: 1em;
}
.login-form .logo {
  background-color: #127ec0;
  height: 3em;
  margin-bottom: 1vh;
  padding: 10px;
  top: -2.75em;
  width: 100%;
}
.login-form .login-button {
  margin-top: 1em;
  width: 100%;
}
.login-form .login-button:hover,
.login-form .login-button:focus {
  background-color: #68d5ae;
  border-color: #68d5ae;
  color: white;
}
.login-form .login-button .icon {
  margin-right: 1em;
}
.login-form .login-input-username {
  margin-bottom: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Login/LoginForm/LoginForm.less"],"names":[],"mappings":"AAIA;EACC,QAAA;EACA,WAAA;AAHD;AACA;EAKE,cAAA;EACA,eAAA;EACA,eAAA;AAHF;AAJA;EAWE,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;AAJF;AAZA;EAoBE,eAAA;EACA,WAAA;AALF;AAOE;;EAEC,yBAAA;EACA,qBAAA;EACA,YAAA;AALH;AAtBA;EA+BG,iBAAA;AANH;AAzBA;EAoCE,kBAAA;AARF","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n@import \"../../../Styles/colors.less\";\n\n.login-form {\n\ttop: 2vh;\n\twidth: 100%;\n\n\t.forgot-password {\n\t\tcolor: @gray-1;\n\t\tcursor: pointer;\n\t\tmargin-top: 1em;\n\t}\n\n\t.logo {\n\t\tbackground-color: @primary;\n\t\theight: 3em;\n\t\tmargin-bottom: 1vh;\n\t\tpadding: 10px;\n\t\ttop: -2.75em;\n\t\twidth: 100%;\n\t}\n\n\t.login-button {\n\t\tmargin-top: 1em;\n\t\twidth: 100%;\n\n\t\t&:hover,\n\t\t&:focus {\n\t\t\tbackground-color: @success-light;\n\t\t\tborder-color: @success-light;\n\t\t\tcolor: white;\n\t\t}\n\n\t\t.icon {\n\t\t\tmargin-right: 1em;\n\t\t}\n\t}\n\n\t.login-input-username {\n\t\tmargin-bottom: 7px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
