import { PersistConfig, createTransform, persistCombineReducers, persistStore } from "redux-persist";
import userReducer from "Data/Reducers/User";
import { store, buildStore } from "Data/Redux/Store";
import UIReducer from "Data/Reducers/UI";
import itemsReducer from "Data/Reducers/Items";
import partNumbersReducer from "Data/Reducers/PartNumbers";
import { disconnectOnLogout, logoutOnUnauthorized } from "Data/Redux/Middleware/Middleware";
import { AppState } from "Data/Objects/AppState";
import storage from "redux-persist/lib/storage";
import produce from "immer";
import { IItem, IPaginatedDataSet } from "Interfaces";
import { defaultPaginatedStateOptions } from "Data/Objects/Redux";
import { ReducersMapObject } from "redux";
import listenerMiddleware from "Data/Redux/Middleware/Listener";

const reducerMap: ReducersMapObject = {
	UI: UIReducer,
	User: userReducer,
	Items: itemsReducer,
	PartNumbers: partNumbersReducer
};

const persistStateTransform = createTransform(
	(inboundState, key) => {
		switch (key) {
			case "Items": {
				const typedState: IPaginatedDataSet<IItem> = inboundState as IPaginatedDataSet<IItem>;

				return produce(typedState, (draft) => {
					draft.data = [];
					draft.lastPage = -1;
					draft.options = { ...typedState?.options, ...defaultPaginatedStateOptions };
				});
			}

			default:
				return inboundState;
		}
	}
);

const persistConfig: PersistConfig<AppState> = {
	key: "root",
	blacklist: [ "Order" ],
	storage,
	transforms: [ persistStateTransform ]
};

buildStore(
	persistCombineReducers(persistConfig, reducerMap),
	[ listenerMiddleware.middleware, disconnectOnLogout, logoutOnUnauthorized ]
);

export const persistor = persistStore(store);
