import { memo } from "react";
import "Components/TitleBar/TitleBar.less";
import TitleBarPopover from "Components/TitleBarPopover";

const TitleBar = () => {
	return (
		<div className="title-bar">
			<TitleBarPopover />
		</div>
	);
};

export default memo(TitleBar);
