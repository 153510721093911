import { createSelector } from "@reduxjs/toolkit";
import { AppState, ItemsState, UIState } from "Data/Objects/AppState";
import { IItem, IItemFilters } from "Interfaces";
import { getPaginatedData } from "Data/Utils/Redux";

// #region Items Selectors Locally Defined To Avoid Circular Dependencies
const getItemsState = (appState: AppState) => {
	return appState?.Items;
};

const getAllItems = createSelector(
	getItemsState,
	getPaginatedData
);
// #endregion

export const getUIState = (appState: AppState) => {
	return appState?.UI;
};

export const getSiderFilters = (uiState: UIState, itemsState: ItemsState): IItemFilters => {
	const {
		"filter[order]": orderNumber, "filter[scannedAfter]": scannedAfter, "filter[scannedBefore]": scannedBefore,
		"filter[partNumber]": partNumbers, "filter[serialNumber]": serialNumber,
		"filter[sequenceBefore]": serialRangeUpper, "filter[sequenceAfter]": serialRangeLower,
		"filter[dateCodeBefore]": serialDateUpper, "filter[dateCodeAfter]": serialDateLower
	} = itemsState.options;

	return {
		orderNumber: orderNumber ? orderNumber.toString() : "",
		scannedAfter: scannedAfter ? scannedAfter.toString() : null,
		scannedBefore: scannedBefore ? scannedBefore.toString() : null,
		scanDateOption: uiState.scanDateOption,
		selectedPartNumbers: partNumbers ? partNumbers.toString().split(",") : [],
		serialNumber: serialNumber ? serialNumber.toString() : "",
		serialRangeUpper: serialRangeUpper ? serialRangeUpper.toString() : "",
		serialRangeLower: serialRangeLower ? serialRangeLower.toString() : "",
		serialDateUpper: serialDateUpper ? serialDateUpper.toString().replace(/(.{2})/g, "$1/") : null,
		serialDateLower: serialDateLower ? serialDateLower.toString().replace(/(.{2})/g, "$1/") : null
	};
};

const getAllFilters = (uiState: UIState) => {
	return uiState.filters;
};

const getSelectedItemsIds = (state: UIState) => {
	return state.selectedItems;
};

const getEntireSelectedItems = (state: UIState, items: IItem[]) => {
	return items.filter((item) => {
		return state.selectedItems.includes(item.id);
	});
};

export const getItemFilters = createSelector(
	[ getUIState, getItemsState ],
	getSiderFilters
);

export const getSelectedItemIds = createSelector(
	getUIState,
	getSelectedItemsIds
);

export const getSelectedItems = createSelector(
	getUIState, getAllItems,
	getEntireSelectedItems
);

export const getFilters = createSelector(
	getUIState,
	getAllFilters
);
