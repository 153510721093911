import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import * as PartNumbersApi from "Data/API/PartNumbers";

const { GET_PART_NUMBERS } = ACTION_TYPES.PartNumbers;

export const getPartNumbersAction = createAsyncThunk(
	GET_PART_NUMBERS,
	async () => {
		return PartNumbersApi.getPartNumbers().then((response) => {
			return response;
		});
	}
);
