import { memo } from "react";
import { Themes, ThemeContext, ToastContainer, Slide } from "@clintonelec/react-storybook";
import TitleBar from "Components/TitleBar";
import { getActiveToken, getActiveUser } from "Data/Selectors/User";
import LoginPage from "Components/Login/LoginPage";
import OrdersSider from "Components/OrdersSider";
import ItemTable from "Components/ItemTable";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAppSelector } from "Data/Redux/Store";

const App = () => {
	const user = useAppSelector(getActiveUser);
	const token = useAppSelector(getActiveToken);

	const renderMainContent = () => {
		const compositeClass = `app-window app-window-${ Themes.LIGHT }`;
		let content = (
			<div className={ compositeClass }>
				<OrdersSider />
				<div className="content-area">
					<TitleBar/>
					<ItemTable />
				</div>
			</div>
		);

		if (!(user && token)) {
			content = <LoginPage />;
		}

		return content;
	};

	return (
		<ThemeContext.Provider value={ Themes.LIGHT }>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={ renderMainContent() } />
					<Route path="/auth/reset-password" element={ <LoginPage forgotPassword /> } />
				</Routes>
				<ToastContainer
					position="top-right"
					autoClose={ 4500 }
					hideProgressBar
					newestOnTop={ false }
					closeOnClick
					rtl={ false }
					pauseOnFocusLoss
					draggable
					pauseOnHover
					transition={ Slide }
					className="cec-toast"
					limit={ 10 }
					style={ { width: 350 } }
					theme={ Themes.LIGHT }
				/>
			</BrowserRouter>
		</ThemeContext.Provider>
	);
};

export default memo(App);
