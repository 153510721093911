import { memo } from "react";
import { Popover, PopoverPlacement, PopoverTrigger } from "@clintonelec/react-storybook";
import "Components/TitleBarPopover/TitleBarPopover.less";
import Icon, { IconSize, IconWeight } from "Components/Icon";
import { getActiveUser } from "Data/Selectors/User";
import { logoutUserAsync } from "Data/Actions/User";
import UserManagementModal from "Components/UserManagementModal";
import CreateUserModal from "Components/CreateUserModal";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";

const TitleBarPopover = () => {
	const user = useAppSelector(getActiveUser);
	const dispatch = useAppDispatch();
	const logout = () => dispatch(logoutUserAsync());
	const clearCache = () => dispatch(clearCache());

	const handleLogout = () => {
		logout().then(clearCache);
	};

	const renderAdminOptions = () => {
		const { admin } = user;

		if (!admin) {
			return null;
		}

		return (
			<UserManagementModal>
				<div className="title-bar-popover-row">
					<Icon
						className="sign-out-icon"
						fixedWidth
						name="users"
						iconWeight={ IconWeight.LIGHT }
					/>
					<span>Edit Users</span>
				</div>
			</UserManagementModal>
		);
	};

	const titleBarContent = (
		<div className="title-bar-content-wrapper">
			<CreateUserModal user={ user }>
				<div className="title-bar-popover-row">
					<Icon
						className="sign-out-icon"
						fixedWidth
						name="pencil"
						iconWeight={ IconWeight.LIGHT }
					/>
					<span>Edit Profile</span>
				</div>
			</CreateUserModal>
			{ renderAdminOptions() }
			<div className="title-bar-popover-row" onClick={ handleLogout }>
				<Icon
					className="sign-out-icon"
					fixedWidth
					name="sign-out"
					iconWeight={ IconWeight.LIGHT }
				/>
				<span>Log Out</span>
			</div>
		</div>
	);

	return (
		<div className="title-bar-popover-wrap">
			<Popover
				className="title-bar-popover"
				content={ titleBarContent }
				trigger={ PopoverTrigger.CLICK }
				placement={ PopoverPlacement.BOTTOM_END }
				arrow={ false }
			>
				<Icon
					className="user-name-title-icon"
					name="user"
					fixedWidth
					size={ IconSize.SMALLER }
				/>
				<span>{ user.username }</span>
			</Popover>
		</div>
	);
};

export default memo(TitleBarPopover);
