import * as React from "react";
import { toast, ToastOptions } from "@clintonelec/react-storybook";

export enum NotificationType {
	SUCCESS = "success",
	ERROR = "error",
	INFO = "info",
	WARNING = "warning"
}

export function notify(
	type: NotificationType, title: string, content?: React.ReactNode, durationMilliseconds?: number | false,
	toastOptions?: ToastOptions
) {
	if (content) {
		const complexContent = (
			<React.Fragment>
				<div className="cec-notification-title">
					{ title }
				</div>
				<div className="cec-notification-content">
					{ content }
				</div>
			</React.Fragment>
		);

		return toast[type](complexContent, { autoClose: durationMilliseconds, ...toastOptions });
	}

	toast[type](title, { autoClose: durationMilliseconds, ...toastOptions });
}
