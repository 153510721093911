import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, SyntheticEvent, memo } from "react";

export type FlipTypes = "horizontal" | "vertical";
type SortDirections = "up" | "down";
export enum IconSize {
	BIGGEST = "5x",
	BIGGER = "4x",
	BIG = "3x",
	SMALL = "2x",
	SMALLER = "lg",
	SMALLEST = "sm"
}

export enum IconWeight {
	REGULAR = "far",
	BRANDS = "fab",
	LIGHT = "fal",
	SOLID = "fas"
}

interface IIconProps {
	sortDirection?: SortDirections;
	flip?: FlipTypes;
	name: string;
	rotate?: number;
	size?: IconSize;
	spin?: boolean;
	// fixedWidth corresponds to Font Awesome's
	// fixed width property which makes all icons
	// the same width... this improves the look
	// of icons used in a list. It defaults to
	// on because it's usually helpful. Turn
	// it off if it causes problems
	fixedWidth?: boolean;
	onClick?: (e?: SyntheticEvent<HTMLSpanElement>) => void;
	className?: string;
	iconWeight?: IconWeight;
}

const Icon = (props: IIconProps) => {
	const {
		name, className, size, spin, flip, rotate, fixedWidth, iconWeight, onClick, sortDirection: direction
	} = props;

	const renderArrow = () => {
		if (!direction) {
			return null;
		}

		const arrowIcon: IconName = direction === "up" ? "long-arrow-alt-down" : "long-arrow-alt-up";

		return (
			<Fragment>
				&nbsp;
				<FontAwesomeIcon
					icon={ [ iconWeight, arrowIcon ] }
					size={ size }
					fixedWidth={ fixedWidth }
				/>
			</Fragment>
		);
	};

	return(
		<span
			onClick={ onClick }
			className={ className }
		>
			{ renderArrow() }
			<FontAwesomeIcon
				icon={ [ iconWeight, name as IconName ] }
				spin={ spin }
				flip={ flip }
				size={ size }
				transform={ { rotate: rotate ?? 0 } }
				fixedWidth={ fixedWidth }
			/>
		</span>
	);
};

export default memo(Icon);
