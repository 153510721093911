import "Components/UserManagementModal/UserManagementModal.less";
import React, { Fragment, useState, memo } from "react";
import { Button, ButtonTypes, Modal, Select } from "@clintonelec/react-storybook";
import UserManagementTable from "Components/UserManagementTable";
import Icon, { IconSize, IconWeight } from "Components/Icon";
import CreateUserModal from "Components/CreateUserModal";
import { Filters, UserRoles, UserStatus } from "Data/Objects/UI";
import { ISetFilterPayload } from "Interfaces";
import { setFilterAction } from "Data/Actions/UI";
import { getFilters } from "Data/Selectors/UI";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";

interface IUserManagementModalProps {
	children?: React.ReactNode;
}

const UserManagementModal = (props: IUserManagementModalProps) => {
	const { children } = props;
	const [ visible, setVisible ] = useState(false);
	const filters = useAppSelector(getFilters);
	const { USER_ROLE, USER_STATUS } = filters;
	const dispatch = useAppDispatch();
	const setFilter = (payload: ISetFilterPayload) => dispatch(setFilterAction(payload));

	const handleSetFilter = (filter: Filters) => (value: UserRoles) => {
		return setFilter({ filter, value });
	};

	const title = (
		<Fragment>
			<Icon
				fixedWidth
				name="users"
				iconWeight={ IconWeight.LIGHT }
			/>
			<span>User Management</span>
		</Fragment>
	);

	const toolbar = (
		<div className="user-management-toolbar">
			<CreateUserModal>
				<Button
					type={ ButtonTypes.SECONDARY }
					ghost
					icon={ { name: "user-plus", size: IconSize.SMALLEST } }
				>
					Create User
				</Button>
			</CreateUserModal>
			<div className="user-management-filters">
				<div className="user-management-filter">
					<div className="label">Role:</div>
					<Select
						options={
							[
								{ value: UserRoles.USER, label: UserRoles.USER },
								{ value: UserRoles.ADMIN, label: UserRoles.ADMIN }
							]
						}
						value={ USER_ROLE }
						allowClear={ false }
						onSelect={ handleSetFilter(Filters.USER_ROLE) }
					/>
				</div>
				<div className="user-management-filter">
					<div className="label">Status:</div>
					<Select
						options={
							[
								{ value: UserStatus.ACTIVE, label: UserStatus.ACTIVE },
								{ value: UserStatus.ALL_USERS, label: UserStatus.ALL_USERS }
							]
						}
						value={ USER_STATUS }
						allowClear={ false }
						onSelect={ handleSetFilter(Filters.USER_STATUS) }
					/>
				</div>
			</div>
		</div>
	);

	const modalContent = (
		<Fragment>
			{ toolbar }
			<UserManagementTable />
		</Fragment>
	);

	return (
		<Modal
			title={ title }
			modalContent={ modalContent }
			width="fit-content"
			visible={ visible }
			onVisibilityChange={ setVisible }
			className="user-management-modal"
		>
			{ children }
		</Modal>
	);
};

export default memo(UserManagementModal);
