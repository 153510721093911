export const ACTION_TYPES = {
	Items: {
		FETCH_ITEMS_PAGE: "FETCH_ITEMS_PAGE",
		SET_ITEMS_OPTIONS: "SET_ITEMS_OPTIONS"
	},
	PartNumbers: {
		GET_PART_NUMBERS: "GET_PART_NUMBERS"
	},
	Pusher: {
		FETCH_ITEMS_PUSHER: "FETCH_ITEMS_PUSHER",
		DELETE_ORDER: "DELETE_ORDER"
	},
	User: {
		LOGIN_USER_ASYNC: "LOGIN_USER_ASYNC",
		LOGOUT_USER: "LOGOUT_USER",
		CREATE_USER: "CREATE_USER",
		UPDATE_USER: "UPDATE_USER",
		DELETE_USER: "DELETE_USER",
		FETCH_USER: "FETCH_USER",
		FETCH_USERS_PAGE: "FETCH_USERS_PAGE"
	},
	UI: {
		CLEAR_ORDER_FORM: "CLEAR_ORDER_FORM",
		SET_SCAN_DATE_OPTION: "SET_SCAN_DATE_OPTION",
		SET_SELECTED_ITEMS: "SET_SELECTED_ITEMS",
		SET_FILTER: "SET_FILTER"
	},
	System: {
		CLEAR_CACHE: "CLEAR_CACHE"
	}
};
