import { ActionReducerMapBuilder, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { getPartNumbersAction } from "Data/Actions/PartNumbers";
import { PartNumbersState } from "Data/Objects/AppState";

const defaultState: PartNumbersState = {
	partNumbers: []
};

export const setPartNumbers = (state: PartNumbersState, action: PayloadAction<string[]>) => {
	state.partNumbers = action?.payload;
};

const reducers = (reducerBuilder: ActionReducerMapBuilder<PartNumbersState>) => {
	reducerBuilder.addCase(getPartNumbersAction.fulfilled.type, setPartNumbers);
};

export default createReducer(defaultState, reducers);
