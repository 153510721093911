import {
	IPaginatedDataSet, IDataPage, IWithId
} from "Interfaces";
import { PayloadAction } from "@reduxjs/toolkit";
import { assign, isEqual } from "lodash";
import { AppState } from "Data/Objects/AppState";
import produce from "immer";

export function getPaginatedDataIndex<T extends IWithId>(state: IPaginatedDataSet<T>, item: T) {
	return state?.data?.findIndex((currentItem: T) => {
		return currentItem?.id === item?.id;
	});
}

export function getIndexbyId<T extends IWithId>(state: IPaginatedDataSet<T>, id: string) {
	return state?.data?.findIndex((currentItem: T) => {
		return currentItem.id === id;
	});
}

export function deletePaginatedItems<T extends IWithId>(state: IPaginatedDataSet<T>,
	action: PayloadAction<string[]>) {

	if (action?.payload) {
		state.data = state.data.filter(device => {
			return !action.payload.includes(device.id);
		});
	}
}

export function deletePaginatedItem<T extends IWithId>(state: IPaginatedDataSet<T>,
	action: PayloadAction<string>) {

	if (action?.payload) {
		const { payload } = action;
		const index = getIndexbyId(state, payload);

		if (index >= 0) {
			state.data.splice(index, 1);
		}
	}
}

export function createPaginatedItem<T extends IWithId>(state: IPaginatedDataSet<T>, action: PayloadAction<T>) {
	if (action?.payload) {
		const index = getPaginatedDataIndex(state, action?.payload);

		if (index < 0) {
			state.data.push(action?.payload);
		}
	}
}

export function updatePaginatedItem<T extends IWithId>(state: IPaginatedDataSet<T>, action: PayloadAction<T>) {
	if (action?.payload) {
		const { payload } = action;
		const index = getPaginatedDataIndex(state, payload);

		if (index >= 0) {
			assign(state.data[index], payload);
		}
	}
}

export function updatePaginatedItems<T extends IWithId>(state: IPaginatedDataSet<T>, action: PayloadAction<T[]>) {
	if (action?.payload) {
		const { payload } = action;

		payload.forEach((item) => {
			const index = state.data.findIndex(currentItem => {
				return currentItem.id === item.id;
			});

			if (index >= 0) {
				assign(state.data[ index ], item);
			}
		});
	}
}

export function createOrUpdatePaginatedItem<T extends IWithId>(state: IPaginatedDataSet<T>,
	action: PayloadAction<T>) {

	if (!action?.payload) {
		return state;
	}

	const index = getPaginatedDataIndex(state, action?.payload);

	if (index < 0) {
		createPaginatedItem(state, action);
	}

	updatePaginatedItem(state, action);
}

export function createOrUpdatePaginatedItems<T extends IWithId>(
	state: IPaginatedDataSet<T>, action: PayloadAction<T[]>
) {
	if (action?.payload) {
		const { payload } = action;

		payload.forEach((item) => {
			const index = state.data.findIndex(currentItem => {
				return currentItem.id === item.id;
			});

			if (index < 0) {
				state.data.push(item);
			} else {
				assign(state.data[ index ], item);
			}
		});
	}
}

export function addOrRemoveFromArray<T>(array: T[], item: T) {
	return produce(array, (draft: T[]) => {
		const index = draft.findIndex(currentItem => {
			return isEqual(currentItem, item);
		});

		if (index < 0) {
			draft.push(item);
		} else {
			draft.splice(index, 1);
		}
	});
}

export const handleClearCache = <T>(defaultState: T) => () => {
	return defaultState;
};

export function handleDataPage<T extends IWithId>(state: IPaginatedDataSet<T>, action: PayloadAction<IDataPage<T>>) {
	const pageNumberExp = new RegExp("page%5Bnumber%5D=([0-9]+)");
	const nextPage = pageNumberExp.exec(action?.payload?.links?.next)?.[1] ?? +state.options["page[number]"] + 1;

	createOrUpdatePaginatedItems(state, { payload: action?.payload?.data, type: action.type });

	if (state.lastPage === -1) {
		state.lastPage = +pageNumberExp.exec(action?.payload?.links?.last)[1];
		state.options[ "page[number]" ] = +nextPage;
	} else {
		state.options[ "page[number]" ] = +nextPage;
	}
}

export function createIdentitySelector<T>() {
	return (appState: AppState, value: T) => (value);
}

export function filterPaginatedDataById<T extends IWithId>(state: IPaginatedDataSet<T>, id: string) {
	return state?.data?.find((item: T) => {
		return item.id === id;
	});
}

export function filterPaginatedItemsById<T extends IWithId>(state: IPaginatedDataSet<T>, ids: string[]) {
	return ids.map((id) => {
		return filterPaginatedDataById(state, id);
	});
}

export function getPaginatedDataOptions<T extends IWithId>(state: IPaginatedDataSet<T>) {
	return state?.options;
}

export function getPaginatedDataFetchable<T extends IWithId>(state: IPaginatedDataSet<T>) {
	const { lastPage, options } = state;

	return (
		lastPage === -1 || options["page[number]"] <= lastPage
	);
}

export function getPaginatedData<T>(state: IPaginatedDataSet<T>) {
	return state?.data;
}
