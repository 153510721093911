// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page {
  display: flex;
  height: 100%;
}
.login-page .center-content {
  border: 1px solid inherit;
  border-radius: 10px;
  box-shadow: #00000059 0 7px 29px 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 325px;
}
.login-page .center-content .login-content {
  align-items: center;
  display: flex;
  padding: 8%;
}
.login-page .login-footer {
  bottom: 0;
  left: 0;
  padding: 5px;
  position: fixed;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Login/LoginPage/LoginPage.less"],"names":[],"mappings":"AAIA;EACC,aAAA;EACA,YAAA;AAHD;AACA;EAKE,yBAAA;EACA,mBAAA;EACA,kCAAA;EACA,SAAA;EACA,kBAAA;EACA,QAAA;EACA,gCAAA;EACA,YAAA;AAHF;AATA;EAeG,mBAAA;EACA,aAAA;EACA,WAAA;AAHH;AAdA;EAsBE,SAAA;EACA,OAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;AALF","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n@import \"../../../Styles/colors.less\";\n\n.login-page {\n\tdisplay: flex;\n\theight: 100%;\n\n\t.center-content {\n\t\tborder: 1px solid inherit;\n\t\tborder-radius: 10px;\n\t\tbox-shadow: #00000059 0 7px 29px 0;\n\t\tleft: 50%;\n\t\tposition: absolute;\n\t\ttop: 50%;\n\t\ttransform: translate(-50%, -50%);\n\t\twidth: 325px;\n\n\t\t.login-content {\n\t\t\talign-items: center;\n\t\t\tdisplay: flex;\n\t\t\tpadding: 8%;\n\t\t}\n\t}\n\n\t.login-footer {\n\t\tbottom: 0;\n\t\tleft: 0;\n\t\tpadding: 5px;\n\t\tposition: fixed;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
