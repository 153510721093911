import {
	Action, AnyAction, configureStore, EnhancedStore, MiddlewareArray, createAsyncThunk as createAsyncThunkBase
} from "@reduxjs/toolkit";
import { ThunkMiddleware } from "redux-thunk";
import { AppState } from "Data/Objects/AppState";
import { Reducer } from "redux";
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { TypedUseSelectorHook, useSelector as reduxUseSelector, useDispatch as reduxUseDispatch } from "react-redux";

// type output from configure store, typed explicitly here as this has an impact on how our store.dispatch can be used
/* eslint-disable @typescript-eslint/no-explicit-any */
export let store: EnhancedStore<any, Action<any>, MiddlewareArray<[(store: any) => (next: any) => (action: any) => any,
	ThunkMiddleware<any, AnyAction, undefined>]>>;
/* eslint-enable @typescript-eslint/no-explicit-any */

export const buildStore = (reducer: Reducer, middleware) => {
	store = configureStore({
		reducer: reducer,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ]
			}
		})
			.prepend(
				middleware
			)
	});
};

export const createAsyncThunk = createAsyncThunkBase.withTypes<{ state: AppState }>();
export const useAppSelector: TypedUseSelectorHook<AppState> = reduxUseSelector;
export const useAppDispatch: () => typeof store.dispatch = reduxUseDispatch;
