import { createSelector } from "@reduxjs/toolkit";
import { AppState, UsersState } from "Data/Objects/AppState";
import { UserRoles, UserStatus } from "Data/Objects/UI";
import { getFilters } from "Data/Selectors/UI";
import { getPaginatedData, getPaginatedDataFetchable, getPaginatedDataOptions } from "Data/Utils/Redux";
import { IFilters, IUser } from "Interfaces";

const getUsersState = (state: AppState) => {
	return state?.User;
};

const getUser = (state: UsersState) => {
	return state?.user;
};

const getToken = (state: UsersState) => {
	return state?.token;
};

const getUsersFiltered = (users: IUser[], filters: IFilters) => {
	const { USER_ROLE, USER_STATUS } = filters;
	const isAdmin = USER_ROLE === UserRoles.ADMIN;
	const onlyActive = USER_STATUS === UserStatus.ACTIVE;
	const newusers = users.filter((user) => {
		return user.admin === isAdmin && (onlyActive ? user.active : true);
	});

	return newusers;
};

export const getAllUsers = createSelector(
	getUsersState,
	getPaginatedData
);

export const getActiveUser = createSelector(
	getUsersState,
	getUser
);

export const getActiveToken = createSelector(
	getUsersState,
	getToken
);

export const getUsersFetchable = createSelector(
	getUsersState,
	getPaginatedDataFetchable
);

export const getUsersOptions = createSelector(
	getUsersState,
	getPaginatedDataOptions
);

export const getFilteredUsers = createSelector(
	[ getAllUsers, getFilters ],
	getUsersFiltered
);
