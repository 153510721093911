import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { createAction } from "@reduxjs/toolkit";
import { ILoginUserArgs, IUser, IWithApiData } from "Interfaces";
import * as UserAPI from "Data/API/User";
import { deserializeJsonApiData } from "Data/Utils/Json";
import { getUsersOptions } from "Data/Selectors/User";
import { createAsyncThunk } from "Data/Redux/Store";

const {
	LOGIN_USER_ASYNC, LOGOUT_USER, CREATE_USER, DELETE_USER, FETCH_USER,
	UPDATE_USER, FETCH_USERS_PAGE
} = ACTION_TYPES.User;

export const loginUserAsync = createAsyncThunk(
	LOGIN_USER_ASYNC,
	async (args: ILoginUserArgs) => {
		return UserAPI.loginUser(args);
	}
);

export const logoutUserAsync = createAsyncThunk(
	LOGOUT_USER,
	async () => {
		return UserAPI.logoutUser();
	}
);

export const logoutUserLocal = createAction(logoutUserAsync.fulfilled.type);

export const createUserAction = createAsyncThunk(
	CREATE_USER,
	async (user: IUser) => {
		return UserAPI.createUser(user);
	}
);

export const createUserLocal = createAsyncThunk(
	CREATE_USER,
	async (user: IWithApiData) => {
		return deserializeJsonApiData<IUser>(user);
	}
);

export const updateUserAction = createAsyncThunk(
	UPDATE_USER,
	async (payload: Partial<IUser>) => {
		return UserAPI.updateUser(payload);
	}
);

export const updateUserLocal = createAsyncThunk(
	UPDATE_USER,
	async (user: IWithApiData) => {
		return deserializeJsonApiData<Partial<IUser>>(user);
	}
);

export const fetchNextUserPage = createAsyncThunk(
	FETCH_USERS_PAGE,
	async (payload, thunkApi) => {
		return UserAPI.getUsers(getUsersOptions(thunkApi.getState()));
	}
);

export const fetchUser = createAsyncThunk(
	FETCH_USER,
	async (id: string) => {
		return UserAPI.getUser(id);
	}
);

export const deleteUser = createAsyncThunk(
	DELETE_USER,
	async (id: string) => {
		UserAPI.deleteUser(id);
	}
);
