import { createAction } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import * as ItemsApi from "Data/API/Items";
import { IIndexSignature } from "Interfaces";
import { getItemsOptions } from "Data/Selectors/Items";
import { createAsyncThunk } from "Data/Redux/Store";

const { FETCH_ITEMS_PAGE, SET_ITEMS_OPTIONS } = ACTION_TYPES.Items;

export const fetchNextItemsPageAction = createAsyncThunk(
	FETCH_ITEMS_PAGE,
	async (payload, thunkApi) => {
		return ItemsApi.getItems(getItemsOptions(thunkApi.getState()));
	}
);

export const setItemsOptionsAction = createAction<IIndexSignature<string | number>>(SET_ITEMS_OPTIONS);
