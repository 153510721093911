export enum UserRoles {
	ADMIN = "Admin",
	USER = "User"
}

export enum UserStatus {
	ACTIVE = "Active",
	ALL_USERS = "All Users"
}

export enum Filters {
	USER_ROLE = "USER_ROLE",
	USER_STATUS = "USER_STATUS"
}
